import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>ICO Consulting</span> Services</h1>
                <p className='pharagraph'>Coinsclone, a top leading and well-known provider of ICO Consulting Services, offers professional support to startups looking to launch an Initial Coin offering (ICO). From strategic planning and technical development to ICO marketing, legal compliance, and post-launch support, Coinsclone ensures your blockchain projects successfully raise funds through our ICO consulting services.</p>
                <p className='pharagraph'>Power Your ICO with Expert Guidance...</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Talk to our Experts</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/ico-consult/ico-consulting-services.webp"
                alt="ICO Consulting Services"
                className='d-none d-md-block float-end'
                width={562}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection